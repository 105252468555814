<template>
  <form
    v-if="tattooer"
    action
    data-vv-scope="tattooer-form"
    @submit.prevent="validateForm('tattooer-form')"
    id="tattoerForm"
  >
    <base-material-alert v-if="userProcessed" color="success" dark>{{
      $t(text_processed)
    }}</base-material-alert>
    <users-form
      ref="user_form"
      :user="tattooer.user"
      :buttons="false"
      :password="
        !$store.getters['auth/isStudio'] ||
          $route.name === 'tattooerGuest' ||
          tattooer.user.role_id == 8
      "
      :disabled="$store.getters['auth/isStudio'] && tattooer.user.role_id !== 8"
    >
      <template v-slot:row2>
        <v-col class="py-0" cols="12" md="6" v-if="false">
          <label>{{ URL }}/?#/inscription/tattooer/ </label>
          <v-text-field
            outlined
            hide-details
            dense
            v-model="tattooer.token"
            data-vv-validate-on="custom"
            v-validate="'required'"
            :error-messages="errors.collect('tattooer-form.token')"
            color="primary"
            data-vv-name="gender"
            @blur="checkToken"
            :disabled="
              $store.getters['auth/isStudio'] && tattooer.user.role_id !== 8
            "
            v-bind:class="[
              {
                'error--text': !token_error,
              },
            ]"
            :hint="!token_error ? 'Token repetido' : ''"
            :placeholder="$t('tattooers.token')"
          >
          </v-text-field>
        </v-col>
      </template>
    </users-form>
    <v-row>
      <v-col class="py-0" cols="12" md="3">
        <label>{{ $t("tattooers.gender") }}</label>
        <v-select
          outlined
          hide-details
          dense
          v-model="tattooer.gender"
          data-vv-validate-on="custom"
          v-validate="''"
          :error-messages="errors.collect('tattooer-form.gender')"
          data-vv-name="gender"
          color="primary"
          :disabled="
            $store.getters['auth/isStudio'] && tattooer.user.role_id !== 8
          "
          :items="genders"
          :item-text="(x) => $tc(x.text)"
        />
      </v-col>

      <v-col class="py-0" cols="12" md="3">
        <label>{{ $t("tattooers.birthdate") }}</label>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
          :disabled="
            $store.getters['auth/isStudio'] && tattooer.user.role_id !== 8
          "
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              hide-details
              dense
              v-validate="'required'"
              :error-messages="errors.collect('tattooer-form.birthdate')"
              data-vv-name="birthdate"
              v-model="computedDateFormatted"
              color="primary"
              :disabled="
                $store.getters['auth/isStudio'] && tattooer.user.role_id !== 8
              "
              readonly
              class="pointer"
              v-on="on"
            />
          </template>

          <v-date-picker
            first-day-of-week="1"
            v-model="tattooer.birthdate"
            color="primary"
            no-title
            ref="picker"
            scrollable
            :show-current="today"
            :max="today"
            @input="menu = false"
          />
        </v-menu>
      </v-col>

      <v-col class="py-0" cols="12" md="3">
        <label>{{ $t("tattooers.nationality") }}</label>

        <country-select
          ref="country"
          :value="tattooer"
          :edit="
            !($store.getters['auth/isStudio'] && tattooer.user.role_id !== 8)
          "
          valueKey="nationality"
          rules=""
        ></country-select>
      </v-col>
      <v-col cols="12" md="3" class="py-0 mt-0 mt-md-0">
        <label v-text="$t('tattooers.artist_type')"></label>
        <v-select
          :items="items"
          outlined
          hide-details
          dense
          multiple
          v-model="tattooer.types"
          :disabled="
            $store.getters['auth/isStudio'] && tattooer.user.role_id !== 8
          "
          item-value="id"
          :item-text="(x) => $tc(x.name)"
          data-vv-validate-on="custom"
          v-validate="'required'"
          :error-messages="errors.collect('tattooer-form.artist-type')"
          data-vv-name="artist-type"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index <= 0">{{ $tc(item.name) }}</span>
            <span v-if="index === 1" class="grey--text text-caption ml-1">
              (+{{ tattooer.types.length - 1 }} más)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="$route.name === 'tattooerEdit'"
        class="py-0 mt-5 mt-md-0"
      >
        <label v-text="$t('inventorys.description')"></label>
        <v-textarea
          class="mt-2"
          rows="3"
          style="border-radius: 10px"
          outlined
          hide-details
          v-model="tattooer.description"
        >
        </v-textarea>
      </v-col>
      <v-col
        cols="12"
        md="3"
        v-if="$route.name === 'tattooerEdit'"
        class="py-0 mt-5 mt-md-0"
      >
        <label v-text="$t('alias')"></label>

        <v-text-field
          outlined
          hide-details
          dense
          v-model="tattooer.alias"
          data-vv-validate-on="blur"
          v-validate="skip_validate ? 'min:2' : 'required|min:2'"
          :error-messages="errors.collect('user-form.alias')"
          :disabled="disabled"
          data-vv-name="alias"
          color="primary"
        />
      </v-col>
      <v-col class="xs" cols="12" md="3" v-if="false">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on }">
            <v-btn
              style="height: 30px"
              block
              outlined
              hide-details
              class="mt-4"
              v-on="on"
              disabled
            >
              {{ $t("tattooers.token_payment") }}
            </v-btn>
          </template>
          <v-card>
            <v-card id="Token">
              <v-card-title
                style="text-decoration: underline; text-transform: uppercase"
                >{{ $t("tattooers.token_payment") }}
              </v-card-title>
              <div class="close">
                <v-btn icon @click="dialog = false" small>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <token-payment
                :tattooer="tattooer"
                :studio_id="$route.params.studio"
              ></token-payment>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  hide-details
                  @click="dialog = false"
                  style="height: 30px; width: 100px"
                  elevation="0"
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  @click="dialog = false"
                  elevation="0"
                  style="height: 30px; width: 100px !important"
                  ><v-icon small style="margin-right: 5px">$save</v-icon
                  >{{ $t("save", { name: "" }) }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-card>
        </v-dialog>
      </v-col>

      <!--<v-col cols="6">
        <label>{{ $t("social_networks") }}</label>

        <social-networks :user="tattooer" />
      </v-col>-->
    </v-row>
    <div class="pa-3 text-right" v-if="buttons !== false">
      <!--<v-btn text @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>-->
      <v-btn
        outlined
        hide-details
        @click="$router.back()"
        style="height: 30px; width: 100px"
        elevation="0"
        class="mr-2"
        >{{ $t("cancel") }}</v-btn
      >
      <v-btn
        type="submit"
        elevation="0"
        style="height: 30px; width: 100px !important"
        ><v-icon small style="margin-right: 5px">$save</v-icon
        >{{ $t("save", { name: "" }) }}</v-btn
      >
    </div>
  </form>
</template>

<script>
import * as moment from "moment";
import { mapActions } from "vuex";
export default {
  name: "TattooersForm",
  components: {
    Timetable: () => import("@/components/ui/Timetable"),
    CountrySelect: () => import("@/components/ui/CountrySelect"),
    SocialNetworks: () => import("@/components/ui/SocialNetworks"),
    UsersForm: () => import("@/components/user/Form"),
    TokenPayment: () => import("@/components/tattooer/TokenPayment"),
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    "tattooer.user.first_name": {
      handler() {
        this.generateToken();
      },
      deep: true,
    },
    "tattooer.user.last_name": {
      handler() {
        this.generateToken();
      },
      deep: true,
    },
  },
  mounted() {
    this.today = moment()
      .subtract(18, "years")
      .format("YYYY-MM-DD");
    this.fetchTypes();
    console.log(this.tattooer);
  },
  props: ["title", "tattooer", "buttons"],
  data() {
    return {
      userProcessed: false,
      menu: false,
      dialog: false,
      today: null,
      items: [],
      genders: [
        { text: "gender.m", value: "m" },
        { text: "gender.f", value: "f" },
      ],
      token_error: true,
    };
  },
  methods: {
    ...mapActions("tattooers", ["getTypeArtist"]),
    fetchTypes() {
      this.getTypeArtist().then((tattooers) => {
        ////console.log(tattooers);
        this.items = tattooers.data;
      });
    },
    ...mapActions("tattooers", ["cheackTokenTattooer"]),
    checkToken() {
      if (this.$route.name !== "tattooerEdit") {
        this.cheackTokenTattooer(this.tattooer.token).then((data) => {
          this.token_error = data.can;
        });
      } else this.token_error = true;
    },
    generateToken() {
      if (this.tattooer.user.first_name && this.tattooer.user.last_name) {
        let token =
          this.tattooer.user.first_name + "_" + this.tattooer.user.last_name;

        token = token.toLowerCase().replace(/ /g, "_");

        this.tattooer.token = token;
        this.generateToken();
      }
    },
    clearErrors() {
      this.errors.clear();
      this.$refs.user_form.errors.clear();
    },
    async validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      await this.checkToken();
      let user_val = await this.$refs.user_form.validateForm("user-form");
      //let country_val = await this.$refs.country.validateForm();
      let form_val = await this.$validator.validateAll(scope);

      if (
        (form_val && user_val && this.token_error) ||
        this.$store.getters["auth/isStudio"]
      ) {
        this.$emit("validated", this.customer);
        //////console.log("validated");
        return true;
      }
      return false;
    },
  },
  computed: {
    computedDateFormatted() {
      if (!this.tattooer.birthdate) return null;
      const [year, month, day] = this.tattooer.birthdate.split("-");
      return `${day}/${month}/${year}`;
    },
    URL() {
      return window.location.origin;
    },
  },
};
</script>

<style lang="sass">
#tattoerForm
  label
    display: block
    line-height: 1
    margin-top: 10px
</style>
