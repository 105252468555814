var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tattooer)?_c('form',{attrs:{"action":"","data-vv-scope":"tattooer-form","id":"tattoerForm"},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm('tattooer-form')}}},[(_vm.userProcessed)?_c('base-material-alert',{attrs:{"color":"success","dark":""}},[_vm._v(_vm._s(_vm.$t(_vm.text_processed)))]):_vm._e(),_c('users-form',{ref:"user_form",attrs:{"user":_vm.tattooer.user,"buttons":false,"password":!_vm.$store.getters['auth/isStudio'] ||
        _vm.$route.name === 'tattooerGuest' ||
        _vm.tattooer.user.role_id == 8,"disabled":_vm.$store.getters['auth/isStudio'] && _vm.tattooer.user.role_id !== 8},scopedSlots:_vm._u([{key:"row2",fn:function(){return [(false)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.URL)+"/?#/inscription/tattooer/ ")]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
            {
              'error--text': !_vm.token_error,
            } ],attrs:{"outlined":"","hide-details":"","dense":"","data-vv-validate-on":"custom","error-messages":_vm.errors.collect('tattooer-form.token'),"color":"primary","data-vv-name":"gender","disabled":_vm.$store.getters['auth/isStudio'] && _vm.tattooer.user.role_id !== 8,"hint":!_vm.token_error ? 'Token repetido' : '',"placeholder":_vm.$t('tattooers.token')},on:{"blur":_vm.checkToken},model:{value:(_vm.tattooer.token),callback:function ($$v) {_vm.$set(_vm.tattooer, "token", $$v)},expression:"tattooer.token"}})],1):_vm._e()]},proxy:true}],null,false,1293689379)}),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t("tattooers.gender")))]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],attrs:{"outlined":"","hide-details":"","dense":"","data-vv-validate-on":"custom","error-messages":_vm.errors.collect('tattooer-form.gender'),"data-vv-name":"gender","color":"primary","disabled":_vm.$store.getters['auth/isStudio'] && _vm.tattooer.user.role_id !== 8,"items":_vm.genders,"item-text":function (x) { return _vm.$tc(x.text); }},model:{value:(_vm.tattooer.gender),callback:function ($$v) {_vm.$set(_vm.tattooer, "gender", $$v)},expression:"tattooer.gender"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t("tattooers.birthdate")))]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"290px","disabled":_vm.$store.getters['auth/isStudio'] && _vm.tattooer.user.role_id !== 8,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-text-field',_vm._g({directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"pointer",attrs:{"outlined":"","hide-details":"","dense":"","error-messages":_vm.errors.collect('tattooer-form.birthdate'),"data-vv-name":"birthdate","color":"primary","disabled":_vm.$store.getters['auth/isStudio'] && _vm.tattooer.user.role_id !== 8,"readonly":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},on))]}}],null,false,79720558),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"first-day-of-week":"1","color":"primary","no-title":"","scrollable":"","show-current":_vm.today,"max":_vm.today},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.tattooer.birthdate),callback:function ($$v) {_vm.$set(_vm.tattooer, "birthdate", $$v)},expression:"tattooer.birthdate"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t("tattooers.nationality")))]),_c('country-select',{ref:"country",attrs:{"value":_vm.tattooer,"edit":!(_vm.$store.getters['auth/isStudio'] && _vm.tattooer.user.role_id !== 8),"valueKey":"nationality","rules":""}})],1),_c('v-col',{staticClass:"py-0 mt-0 mt-md-0",attrs:{"cols":"12","md":"3"}},[_c('label',{domProps:{"textContent":_vm._s(_vm.$t('tattooers.artist_type'))}}),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"items":_vm.items,"outlined":"","hide-details":"","dense":"","multiple":"","disabled":_vm.$store.getters['auth/isStudio'] && _vm.tattooer.user.role_id !== 8,"item-value":"id","item-text":function (x) { return _vm.$tc(x.name); },"data-vv-validate-on":"custom","error-messages":_vm.errors.collect('tattooer-form.artist-type'),"data-vv-name":"artist-type"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [(index <= 0)?_c('span',[_vm._v(_vm._s(_vm.$tc(item.name)))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption ml-1"},[_vm._v(" (+"+_vm._s(_vm.tattooer.types.length - 1)+" más) ")]):_vm._e()]}}],null,false,4007923914),model:{value:(_vm.tattooer.types),callback:function ($$v) {_vm.$set(_vm.tattooer, "types", $$v)},expression:"tattooer.types"}})],1),(_vm.$route.name === 'tattooerEdit')?_c('v-col',{staticClass:"py-0 mt-5 mt-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',{domProps:{"textContent":_vm._s(_vm.$t('inventorys.description'))}}),_c('v-textarea',{staticClass:"mt-2",staticStyle:{"border-radius":"10px"},attrs:{"rows":"3","outlined":"","hide-details":""},model:{value:(_vm.tattooer.description),callback:function ($$v) {_vm.$set(_vm.tattooer, "description", $$v)},expression:"tattooer.description"}})],1):_vm._e(),(_vm.$route.name === 'tattooerEdit')?_c('v-col',{staticClass:"py-0 mt-5 mt-md-0",attrs:{"cols":"12","md":"3"}},[_c('label',{domProps:{"textContent":_vm._s(_vm.$t('alias'))}}),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.skip_validate ? 'min:2' : 'required|min:2'),expression:"skip_validate ? 'min:2' : 'required|min:2'"}],attrs:{"outlined":"","hide-details":"","dense":"","data-vv-validate-on":"blur","error-messages":_vm.errors.collect('user-form.alias'),"disabled":_vm.disabled,"data-vv-name":"alias","color":"primary"},model:{value:(_vm.tattooer.alias),callback:function ($$v) {_vm.$set(_vm.tattooer, "alias", $$v)},expression:"tattooer.alias"}})],1):_vm._e(),(false)?_c('v-col',{staticClass:"xs",attrs:{"cols":"12","md":"3"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-4",staticStyle:{"height":"30px"},attrs:{"block":"","outlined":"","hide-details":"","disabled":""}},on),[_vm._v(" "+_vm._s(_vm.$t("tattooers.token_payment"))+" ")])]}}],null,false,2743359091),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card',{attrs:{"id":"Token"}},[_c('v-card-title',{staticStyle:{"text-decoration":"underline","text-transform":"uppercase"}},[_vm._v(_vm._s(_vm.$t("tattooers.token_payment"))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('token-payment',{attrs:{"tattooer":_vm.tattooer,"studio_id":_vm.$route.params.studio}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"height":"30px","width":"100px"},attrs:{"outlined":"","hide-details":"","elevation":"0"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-btn',{staticStyle:{"height":"30px","width":"100px !important"},attrs:{"elevation":"0"},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v("$save")]),_vm._v(_vm._s(_vm.$t("save", { name: "" })))],1)],1)],1)],1)],1)],1):_vm._e()],1),(_vm.buttons !== false)?_c('div',{staticClass:"pa-3 text-right"},[_c('v-btn',{staticClass:"mr-2",staticStyle:{"height":"30px","width":"100px"},attrs:{"outlined":"","hide-details":"","elevation":"0"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-btn',{staticStyle:{"height":"30px","width":"100px !important"},attrs:{"type":"submit","elevation":"0"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v("$save")]),_vm._v(_vm._s(_vm.$t("save", { name: "" })))],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }